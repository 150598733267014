<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Page-privacy component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level">
                        <h4 class="title"> FTC Diclosure</h4>
                        <ul class="list-unstyled mt-4">
                            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Last Revised :</span> 23th Sep, 2020</li>
                        </ul>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item"><a href="/terms">Terms</a></li>
                                    <li class="breadcrumb-item"><a href="/privacy">Privacy</a></li>
                                    <li class="breadcrumb-item"><a href="/refund">Refund</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Diclosure</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--Shape End-->

    <!-- Start Privacy -->
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="card shadow rounded border-0">
                        <div class="card-body">
  






                          <h5 class="card-title">Welcome to Karukaj DIGITAL</h5>

<ol>
 	<li>"Karukaj DIGITAL" may contain advertisements, sponsored content, paid insertions, affiliate links or other forms of monetization.</li>
 	<li>"Karukaj DIGITAL" abides by word of mouth marketing standards. We believe in honesty of relationship, opinion, and identity. The compensation received may influence the advertising content, topics or posts made in this blog. That content, advertising space or post will be clearly identified as paid or sponsored content.</li>
 	<li>Affiliate links are tracking links that are given to me whenever we become an affiliate with a certain company. If you click on our link and purchase the product that we're promoting, we'll receive a commission.</li>
 	<li>Please know that no extra cost or hidden fees will be added to your purchase just because we receive a commission. Also, you don’t have to click through our links if you don’t want to. That decision is totally up to you.</li>
 	<li>The only products that we recommend are products that we use and love, and we would never make any false statements about a product that we're promoting. If you have any questions about affiliate links, let me know in the comment section below.</li>
 	<li>Please do not spend any money on these products unless you feel that they will help you to meet your goals and gain more freedom in your teaching or personal life.</li>
</ol>
<h4><strong>Contact</strong></h4>
If you have any questions regarding this policy or your dealings with our website, please contact us through our <a href="/support/">suppot center</a> or info below.





<br><br>



                            <a href="javascript:void(0)" class="btn btn-primary">Print</a>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->




<br><br><br>
      
        <div class="container mt-4 mt-lg-0">
            <div class="row align-items-center mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                        <h4 class="mb-4">Need help? We're always here for you</h4>
                        <p class="text-muted mb-0 para-desc">Start working with <span class="my-color-1 font-weight-bold">Karukaj</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">
                        <a href="/support" class="btn btn-soft-primary">Visit Support Center<arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

        </div>
        <!--end container-->





    </section>
    <!--end section-->
    <!-- End Privacy -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-light back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
